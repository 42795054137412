@font-face {
    font-family: HelveticaNeue;
    src: url("src/assets/fonts/HelveticaNeue/35 Thin.ttf") format('truetype');
    font-weight: lighter;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: HelveticaNeue;
    src: url("src/assets/fonts/HelveticaNeue/35 Thin.ttf") format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: HelveticaNeue;
    src: url("src/assets/fonts/HelveticaNeue/45 Light.ttf") format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: HelveticaNeue;
    src: url("src/assets/fonts/HelveticaNeue/45 Light.ttf") format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: HelveticaNeue;
    src: url("src/assets/fonts/HelveticaNeue/55 Roman.ttf") format('truetype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: HelveticaNeue;
    src: url("src/assets/fonts/HelveticaNeue/55 Roman.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: HelveticaNeue;
    src: url("src/assets/fonts/HelveticaNeue/56 Italic.ttf") format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: HelveticaNeue;
    src: url("src/assets/fonts/HelveticaNeue/65 Medium.ttf") format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: HelveticaNeue;
    src: url("src/assets/fonts/HelveticaNeue/65 Medium.ttf") format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: HelveticaNeue;
    src: url("src/assets/fonts/HelveticaNeue/75 Bold.ttf") format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: HelveticaNeue;
    src: url("src/assets/fonts/HelveticaNeue/75 Bold.ttf") format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: HelveticaNeue;
    src: url("src/assets/fonts/HelveticaNeue/75 Bold.ttf") format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: HelveticaNeue;
    src: url("src/assets/fonts/HelveticaNeue/75 Bold.ttf") format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
