/* You can add global styles to this file, and also import other style files */
@import url("bootstrap/dist/css/bootstrap.css");
@import url("src/assets/fonts/HelveticaNeue/styles.css");

:root,
html,
body,
small {
  --dl-space-space-unit: 16px;
  --dl-radius-radius-radius8: 8px;
  --dl-color-gray-700: #999;
  --dl-color-danger-thermo: #ec2b1bff;
  --dl-space-space-halfunit: 8px;
  --button-primary: #E71316;
  --brand-primary: #E71316;
  --status-error: #E71316;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
  overflow: hidden;
}

kmd-tooltip-content .kmd-tooltip .kmd-tooltip-text {
  font-family: inherit !important;
}

.ux-revamp {
  height: calc(100vh - 58px);
  min-height: -webkit-fill-available;
}

/* Reset general */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
.h1 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 40px;
}

h2,
.h2 {
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 32px;
}

h3,
.h3 {
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 28px;
}

h4,
.h4 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 24px;
}

h5,
.h5 {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 22px;
}

h6,
.h6 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 20px;
}

p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 26px;
}

app-add-to-my-agents {
  width: 100%;
  overflow-y: auto;
}

.icon-24-rating-bold::before {
  font-size: 24px;
  content: "\ec3a";
}

app-chat-with-db,
app-chat-response,
app-configure-db,
app-sql,
app-column,
app-ddl,
app-ask-our-data {
  width: 100%;
  overflow: scroll;
}

app-chat-with-db {
  display: flex;
}

@font-face {
  font-family: icomoon;
  src: url('src/assets/fonts/icomoon-old.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

.library-icomoon [class^="icon-"],
.library-icomoon [class*="icon-"] {
  font-family: icomoon;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clock-mono::before {
  content: "\e945";
  font-size: 16px;
  vertical-align: middle;
  padding-right: 5px;
}

.icon-search-bold::before {
  content: "\ea81";
}

.icon-search-mono::before {
  content: "\ea84";
}

.icon-arrow-left-mono::before {
  content: "\e90f"
}

.icon-edit-bold::before {
  content: "\E9BA"
}

.icon-edit-mono::before {
  content: "\EB8A"
}

.icon-trash-mono::before {
  content: "\EAEF"
}

.icon-open-chat-mono::before {
  content: "\EB04"
}

.icon-options-mono::before {
  content: "\EC04"
}

.icon-settings-bold::before {
  content: "\EA87"
}

.icon-eye-open-mono::before {
  content: "\EAC3"
}

.icon-eye-closed-mono::before {
  content: "\EAC1"
}

.icon-warn-mono::before {
  content: '\EC94';
}

.icon-error-bold::before {
  content: '\EAC4';
}

.icon-close-bold::before {
  content: '\E946';
}

.icon-external-link-mono::before {
  content: '\ED5B';
}

.icon-copy-bold::before {
  content: '\EA3F';
}

.icon-plus-bold::before {
  content: '\EDBB';
}

.icon-warn-bold::before {
  content: '\EA0F';
}

.icon-info-mono::before {
  content: '\EA10';
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

tfs-prompt {
  width: 100%;
}

tfs-prompt-controls {
  bottom: 34px;
  width: 90%;
  background: #fff;
}

/* Markdown Styling */
.markdown-wrapper.message {
  display: flex !important;
  flex-direction: column;
  width: auto;
}

.markdown-wrapper.message a {
  color: #007bff;
}

.markdown-wrapper.message.user p {
  margin: 0;
}

.markdown-wrapper.message.user p,
.markdown-wrapper.message.assistant p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
}

.markdown-wrapper.message pre {
  background-color: #272822;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
  margin-bottom: 20px;
}

.markdown-wrapper.message img {
  max-width: 100%;
  height: auto;
}

.markdown-wrapper.message blockquote {
  border-left: 4px solid #ccc;
  margin: 1em 0;
  padding: 0 15px;
  color: #777;
}

.markdown-wrapper.message pre::-webkit-scrollbar {
  -webkit-appearance: none;
}

.markdown-wrapper.message pre::-webkit-scrollbar:horizontal {
  height: 8px;
}

.markdown-wrapper.message pre::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #b2b2bb;
}

.markdown-wrapper.message table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1em;
  display: block;
  overflow-x: auto;
}

.markdown-wrapper.message th,
.markdown-wrapper.message td {
  padding: 8px;
}

.markdown-wrapper.message.assistant th,
.markdown-wrapper.message.assistant td {
  border-bottom: 1px solid #ddd;
}

.markdown-wrapper.message.user th,
.markdown-wrapper.message.user td {
  border-bottom: 1px solid #54545C;
}

.markdown-wrapper.message ul,
.markdown-wrapper.message ol {
  margin: 1em 0;
  padding: 0 0 0 40px;
}

.markdown-wrapper.message li {
  margin-bottom: 0.5em;
}

.markdown-wrapper.message div.mermaid svg {
  margin-bottom: 20px;
}

.ask-our-docs-new-chat-wrapper .wrapper prompt-input-new{
  max-width: unset !important;
  width: 90% !important;
}

.ask-our-docs-new-chat-wrapper .wrapper{
  padding-top: 0 !important;
}

.icon-down-arrow::before {
  content: '\E90C';
  font-size: 16px;
}

.icon-up-arrow::before {
  content: '\E912';
  font-size: 16px;
}

.icon-delete::before {
  content: '\E91F';
  font-size: 16px;
}

.header-section .generating-response-container kmd-loading-wheel .kmd-loader-placement{
  margin-top: 0;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.feedback-container .alert-container-important kmd-alerts .kmd-alerts-parents-holder .kmd-alerts-container{
  border-left: 8px solid #0091ff;
}

.feedback-container .alert-container-important kmd-alerts .kmd-alerts-parents-holder .kmd-alerts-container .kmd-alerts-info .kmd-icon-alert {
  color: #0091ff;
}

.dropdown-linear kmd-dropdown{
  border: 0;
  margin: 0;
  padding-top: 0;
}

.dropdown-linear kmd-dropdown .kmd-dropdown-container p{
  display: none;
}

.p-dialog-content {
  border-radius: 10px 10px 0 0;
}

app-prompt-page .example-wrapper .example app-chip .chip-wrapper {
  background-color: #E5E5E5 !important;
  color: #2B2B30 !important; /* Set the text color */
}

app-ask-our-docs-chat-page app-prompt-page{
  overflow-y: scroll;
}

.card-header .dropdowns-container .kmd-dropdown-select span{
  font-size: 14px;
  font-family: inherit !important;
}

.toggle-container .dropdown-prompt-suggestions .kmd-dropdown-select span{
  font-size: 14px;
  font-family: inherit !important;
}

.repo-name-container app-context-menu .down-to-up-context-menu{
  top: 6.5rem;
  bottom: unset;
}

.subtitle-angular-editor .angular-editor-wrapper .angular-editor-textarea {
  margin-top: 0 !important;
  padding: .375rem 16px !important;
  font-size: 14px;
  line-height: 26px !important;
  resize: none !important;
  outline: none !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.subtitle-angular-editor .angular-editor-wrapper .angular-editor-textarea:focus {
  border-color: #80bdff !important;
  box-shadow: 0 2px 6px #dcdcdc !important;
}

.aod-user-manage-container kmd-toggle .kmd-toggle {
  width: 34px !important;
  height: 20px !important;
}

.aod-user-manage-container kmd-toggle .kmd-toggle-slider::before {
  height: 16px !important;
  width: 16px !important;
  bottom: 2px !important;
  left: 3px;
}

.aod-user-manage-container .kmd-pagination .kmd-pagination-element-active {
  background-color: #E71316;
  color: #fff !important;
  width: 22px;
  height: 22px;
  border-radius: 2px;
}

.aod-user-manage-container .kmd-pagination .kmd-pagination-element-active:hover {
  color: white !important;
}

.aod-user-manage-container .kmd-pagination .kmd-pagination-next,
.aod-user-manage-container .kmd-pagination .kmd-pagination-prev, 
.aod-user-manage-container .kmd-pagination .kmd-state-disabled {
  display: flex !important;
  border: none !important;
  gap: 0.5rem;
  background-color: transparent !important;
}

.aod-user-manage-container .kmd-pagination .kmd-pagination-page-links-section {
  display: flex;
  gap: 0.5rem;
}

.aod-user-manage-container .kmd-pagination .kmd-pagination-prev::after {
  content: 'Prev' !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 22px !important;
  letter-spacing: 0 !important;
  text-align: center !important;
  color: #54545C !important;
}

.aod-user-manage-container .kmd-pagination .kmd-pagination-next::before {
  content: 'Next' !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 22px !important;
  letter-spacing: 0 !important;
  text-align: center !important;
  color: #54545C !important;
}

.aod-user-manage-container .kmd-pagination .kmd-pagination-pages {
  margin-left: 2rem !important;
}

.aod-user-manage-container .kmd-pagination .kmd-pagination-results-text {
  color: #54545C !important;
}

.aod-user-manage-container .kmd-pagination .kmd-pagination-results-text:nth-of-type(4) {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  letter-spacing: 0 !important;
  text-align: center !important;
  color: #1B1B1D !important;
}

.aod-user-manage-container .kmd-dropdown-select p {
  display: none !important;
}

.aod-user-manage-container .kmd-dropdown-select span.kmd-dropdown-medium-padding-with-floating-label {
  padding-top: 8px !important;
  height: 40px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #70707A !important;
  border-left: 0 !important;
}

.aod-user-manage-container .kmd-dropdown-select .kmd-arrow {
  top: 18px !important;
}

.aod-user-manage-container .kmd-dropdown-select {
  height: 40px;
  width: 260px;
}

.aod-user-manage-content p-tabview ul li{
  width: 128px;
  height: 48px;
  display: flex;
}

.aod-user-manage-content p-tabview ul li a span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #54545C;
}

.aod-user-manage-content p-tabview ul .p-highlight a span {
  font-weight: 700;
  color: #1B1B1D;
}

.aod-user-manage-content p-tabview ul {
  padding-left: 0;
  border-bottom-color: transparent;
}

.aod-user-manage-content p-tabview ul li a {
  width: 100%; 
  display: flex;
  justify-content: center;
}
  
.aod-user-manage-content p-tabview ul .p-highlight a {
  border-bottom-color: #E71316;
}